import service from "@/api/service";
import { SearchRequest } from "@/api/talk-user-list/request";
import { SearchResponse } from "@/api/talk-user-list/response";

/**
 * トークリスト取得APIをコールします。
 *
 * @param searchRequest トークリスト取得のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/talk-user-list", searchRequest);
  return response.data as SearchResponse;
}
