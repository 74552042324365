import service from "@/api/service";
import { UpdateRequest } from "@/api/talk-flg/request";
import { CommonResponse } from "@/api/response";

/**
 * トーク情報フラグ更新APIをコールします。
 *
 * @param updateRequest トーク情報フラグ更新のリクエストボディ
 * @return CommonResponse
 */
export async function update(updateRequest: UpdateRequest) {
  const response = await service.post("/talk-flg", updateRequest);
  return response.data as CommonResponse;
}
