import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
// 20210125 NEW_DEV-743 cyber 肖 start
import UITextField from "@/components/UITextField.vue";
// 20210125 NEW_DEV-743 cyber 肖 end
import { SearchRequest } from "@/api/talk-user-list/request";
import { UpdateRequest } from "@/api/talk-flg/request";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import TalkUserListSearch, { ListItem } from "@/store/talk-user-list/search";
import TalkFlgUpdate from "@/store/talk-flg/update";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { Route } from "vue-router/types/router";
import WindowSizeChecker from "@/utils/window-size-checker";
// 20210113 NEW_DEV-743 cyber 肖 start
import { Base64 } from "@/utils/sound";
// 20210113 NEW_DEV-743 cyber 肖 end

const SELECTED_COLOR = "primary";
const UNSELECTED_COLOR = "blue-grey lighten-3";
// 20210125 NEW_DEV-743 cyber 肖 start
@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDatePicker, UITextField } })
// 20210125 NEW_DEV-743 cyber 肖 end
export default class talk extends Vue {
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "コミュニケーション";
  headingSub = "Communication";
  // 20210120 トーク機能追加 cyber 李 start
  breadCrumbs = [
    { text: "コミュニケーション", disabled: true },
    {
      text: "トーク一覧",
      disabled: false,
      to: { name: "communication" }
    },
    { text: "トーク確認", disabled: true }
  ];
  // 20210120 トーク機能追加 cyber 李 end
  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    /** 店舗ID */
    id: 0,
    /** タイプ */
    type: "all" as "all" | "reply" | "flg",
    /** 検索文字 */
    searchWord: null as string | null,
    /** 検索日 */
    searchDate: null as string | null
  } as SearchRequest;
  // 20210118 NEW_DEV-743 cyber 肖 start
  loopsiloopInputOptions = {
    /** 店舗ID */
    id: 0,
    /** タイプ */
    type: "all" as "all" | "reply" | "flg",
    /** 検索文字 */
    searchWord: null as string | null,
    /** 検索日 */
    searchDate: null as string | null
  } as SearchRequest;
  // 20210118 NEW_DEV-743 cyber 肖 end

  // テーブル検索オプション
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ボタンのカラー
  allColor = SELECTED_COLOR;
  replyColor = UNSELECTED_COLOR;
  flgColor = UNSELECTED_COLOR;

  // ボタンのスタイル
  allOutlined = false;
  replyOutlined = true;
  flgOutlined = true;

  // 20210113 NEW_DEV-743 cyber 肖 start
  timer = null as number | null;

  newDataFlg = false;

  newSuccessFlg = false;

  failed = 0;

  oldData = null;
  //NEW_DEV-1484 cyber start
  talkFlgArr = new Array<number>();
  //NEW_DEV-1484 cyber end
  // 20210113 NEW_DEV-743 cyber 肖 end

  // NEW_DEV-1481 cyber start

  pageTotal = 0;

  pageArr = [];
  // NEW_DEV-1481 cyber end

  // ------------  
  /**
   * 総件数
   */
  get totalCount() {
    return TalkUserListSearch.getTotalCount;
  }
  /**
   * リストに表示するアイテムリスト
   */
  get listItems() {
    return TalkUserListSearch.getItems;
  }

  // 20210113 NEW_DEV-743 cyber 肖 start
  // /**
  //  * ショップの設定
  //  */
  get shopSetting() {
    return TalkUserListSearch.getSetting;
  }
  // 20210113 NEW_DEV-743 cyber 肖 end

  // NEW_DEV-1481 cyber start
  get perpageNum() {
    return TalkUserListSearch.getperpageNum;
  }
  // NEW_DEV-1481 cyber end

  /**
   * ショップ名
   */
  get shopName() {
    return this.$route.query.shop_name ? this.$route.query.shop_name : "";
  }

  /**
   * 幅がSPか否か
   */
  get isPhone() {
    return WindowSizeChecker.isPhone(this);
  }

  /**
   * 幅がタブレット以上か否か
   */
  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  /**
   * window幅に応じて router-link か aタグかを返却する
   */
  get componentIs() {
    return this.isPhone ? "a" : "router-link";
  }

  /**
   * window幅に応じて target="_blank" か target="_self" かを返却する
   */
  get linkTarget() {
    return this.isPhone ? "_blank" : "_self";
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    this.inputOptions.id = Number(this.id);
    // 20210118 NEW_DEV-743 cyber 肖 start
    this.loopsiloopInputOptions.id = Number(this.id);
    this.tableOptions.page = 1;
    this.tableOptions.displayCount = 100;
    await this.restoreParams();

    await this.search();
    this.loopsiloop(60000);
    // 20210118 NEW_DEV-743 cyber 肖 end
    // NEW_DEV-1481 cyber start
    // this.tableOptions.displayCount = this.perpageNum;
    this.registUserPage();
    // NEW_DEV-1481 cyber end
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TalkUserListSearch.clearResponse();
    // 20210118 NEW_DEV-743 cyber 肖 start
    clearTimeout(this.timer);
    this.timer = null;
    // 20210118 NEW_DEV-743 cyber 肖 end
  }

  /**
   * $routeのウォッチャー
   *
   * @param newRoute 遷移先のRoute
   * @param oldRoute 遷移元のRoute
   */
  @Watch("$route")
  watchRouter(newRoute: Route, oldRoute: Route) {
    if (newRoute.name === "communication-talk") {
      this.search().then();
    }
  }

  // 20210113 NEW_DEV-743 cyber 肖 start
  /**
   * リストに表示するアイテムリストのウォッチャー
   */
  @Watch("listItems")
  async listItemsChange() {
    let shop_name = this.shopName;
    if(this.shopSetting.isSound == 1 || this.shopSetting.isPopup == 1) {
      if(this.oldData == null) {
        this.oldData = this.listItems;
        for (let i in this.listItems) {
          if(this.listItems[i].unreadCount != 0) {
            if(this.shopSetting.isSound == 1 && this.shopSetting.isPopup == 1) {
              let sound = new Audio("data:audio/mp3;base64," + Base64);
              sound.play();
              sound.addEventListener('ended',function() {
                alert(shop_name + '\n新着トークがあります。');
              });
            }else if(this.shopSetting.isSound == 1) {
              let sound = new Audio("data:audio/mp3;base64," + Base64);
              sound.play();
            }else if(this.shopSetting.isPopup == 1) {
              setTimeout( function() {
                alert(shop_name + '\n新着トークがあります。');
              }, 1000 );
            }
            return;
          }
        }
        return; 
      }
      
      for (let i in this.listItems) {
        if(this.oldData[i].unreadCount < this.listItems[i].unreadCount){
          if(this.shopSetting.isSound == 1 && this.shopSetting.isPopup == 1) {
            let sound = new Audio("data:audio/mp3;base64," + Base64);
            sound.play();
            sound.addEventListener('ended',function() {
              alert(shop_name + '\n新着トークがあります。');
            });
          }else if(this.shopSetting.isSound == 1) {
            let sound = new Audio("data:audio/mp3;base64," + Base64);
            sound.play();
          }else if(this.shopSetting.isPopup == 1) {
            setTimeout( function() {
              alert(shop_name + '\n新着トークがあります。');
            }, 1000 );
          }
          this.oldData = this.listItems;
          return;
        }
      }
      this.oldData = this.listItems;
    }   
  }
  // 20210113 NEW_DEV-743 cyber 肖 end

  /**
   * window幅に応じて router-link のパラメータを返却する
   */
  linkTo(item: ListItem) {
    return this.isPhone
      ? ""
      : {
          path: `/communication/${this.id}/talk/${item.talkId}`,
          query: {
            shop_name: this.shopName,
            user_id: item.userId,
            user_name: item.userName
          }
        };
  }

  /**
   * window幅に応じて href のパラメータを返却する
   */
  linkHref(item: ListItem) {
    return this.isPhone
      ? `/communication/${this.id}/talk/${item.talkId}/mobile?shop_name=${this.shopName}&user_id=${item.userId}&user_name=${item.userName}`
      : "";
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * 全トークボタンが押下された際のコールバック
   */
  async allTalkClickCallback() {
    //NEW_DEV-1484 cyber start
    this.talkFlgArr = [];
    //NEW_DEV-1484 cyber end
    this.inputOptions.type = "all";
    // 20210118 NEW_DEV-743 cyber 肖 start
    this.loopsiloopInputOptions.type = "all";
    // 20210118 NEW_DEV-743 cyber 肖 end
    this.changeButtonParams();
    await this.searchClickCallback();
    this.registUserPage();
  }

  /**
   * 未送信トークボタンが押下された際のコールバック
   */
  async replyTalkClickCallback() {
    //NEW_DEV-1484 cyber start
    this.talkFlgArr = [];
    //NEW_DEV-1484 cyber end
    this.inputOptions.type = "reply";
    // 20210118 NEW_DEV-743 cyber 肖 start
    this.loopsiloopInputOptions.type = "reply";
    // 20210118 NEW_DEV-743 cyber 肖 end
    this.changeButtonParams();
    await this.searchClickCallback();
    this.registUserPage();
  }

  /**
   * フラグ設定トークボタンが押下された際のコールバック
   */
  async flgTalkClickCallback() {
    //NEW_DEV-1484 cyber start
    this.talkFlgArr = [];
    //NEW_DEV-1484 cyber end
    this.inputOptions.type = "flg";
    // 20210118 NEW_DEV-743 cyber 肖 start
    this.loopsiloopInputOptions.type = "flg";
    // 20210118 NEW_DEV-743 cyber 肖 end
    this.changeButtonParams();
    await this.searchClickCallback();
    // NEW_DEV-1481 cyber start
    this.registUserPage();
    // NEW_DEV-1481 cyber end
  }

  /**
   * Flagが押下された際のコールバック
   *
   * @param item
   */
  //NEW_DEV-1484 cyber start
  async flgClickCallback(item: ListItem) {
    await Flash.clear();
    let userId = 't' + item.userId;
    if(this.talkFlgArr[userId] != undefined) {
      if(this.talkFlgArr[userId] == 1 || item.isOnFlag == true){
        this.talkFlgArr[userId] = 0;
      }else{
        this.talkFlgArr[userId] = 1;
      }
    }else{
      if(item.isOnFlag == true) {
        this.talkFlgArr[userId] = 0;
      }else{
        this.talkFlgArr[userId] = 1;
      }
    }
    if(item.talkId && item.talkId != undefined) {
      await this.update(item.talkId);
    }else{
      await this.search();
    }
  }
  
  getTpFlg(item: ListItem) {
    let userId = 't' + item.userId;
    if(this.talkFlgArr[userId] != undefined) {
      if(this.talkFlgArr[userId] == 1) {
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
    
  }
  //NEW_DEV-1484 cyber end
  /**
   * 日付が変更された際のコールバック
   *
   * @param date 選択された日付
   */
  changeCallback(date: string | null) {
    this.inputOptions.searchDate = date;
  }

  // 20210125 NEW_DEV-743 cyber 肖 start
  /**
   * 名前,IDが変更された際のコールバック
   *
   * @param word
   */
  textchangeCallback(word: string | null) {
    this.inputOptions.searchWord = word;
  }
  // 20210125 NEW_DEV-743 cyber 肖 end
  /**
   * 検索処理
   */
  async search() {
    if(this.isLoading) return;
    this.isLoading = true;
    
    let request = this.createRequest();
    await TalkUserListSearch.search(request);

    if (!TalkUserListSearch.isSuccess) {
      await Flash.setErrorNow({
        message: TalkUserListSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }
  // 20210118 NEW_DEV-743 cyber 肖 start
  /**
   * ポーリング検索処理
   */
  async loopsiloopSearch() {
    if(this.isLoading) return;
    
    let request = this.loopsiloopCreateRequest();
    await TalkUserListSearch.search(request);

    if (!TalkUserListSearch.isSuccess) {
      await Flash.setErrorNow({
        message: TalkUserListSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }
  // 20210118 NEW_DEV-743 cyber 肖 end
  /**
   * フラグ更新処理
   */
  async update(talkId: number) {
    if (this.isLoading || !TalkUserListSearch.isSuccess) {
      return;
    }

    const request = { talkId: talkId } as UpdateRequest;
    await TalkFlgUpdate.update(request);
    if (TalkFlgUpdate.isSuccess) {
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: TalkFlgUpdate.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  goToIndex() {
    this.$router.push({ name: "communication" }).then();
  }

  /**
   * 検索パラメータの復元
   */
  private async restoreParams() {
    // トーク機能有効店舗
    await TalkUserListSearch.restore(this.inputOptions as SearchRequest);
    // 20210107 NEW_DEV-743 cyber 肖 start
    const request = TalkUserListSearch.getSearchRequest;
    // 20210107 NEW_DEV-743 cyber 肖 end
    // 検索入力オプション
    request.searchWord && (this.inputOptions.searchWord = request.searchWord);
    request.searchDate && (this.inputOptions.searchDate = request.searchDate);
    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    this.changeButtonParams();
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest() {
    const request = this.tableOptions as SearchRequest;
    request.id = this.inputOptions.id;
    request.type = this.inputOptions.type;
    request.searchWord = this.inputOptions.searchWord;
    request.searchDate = this.inputOptions.searchDate;

    // 20210118 NEW_DEV-743 cyber 肖 start
    this.loopsiloopInputOptions.id = this.inputOptions.id;
    this.loopsiloopInputOptions.type = this.inputOptions.type;
    this.loopsiloopInputOptions.searchWord = this.inputOptions.searchWord;
    this.loopsiloopInputOptions.searchDate = this.inputOptions.searchDate;
    // 20210118 NEW_DEV-743 cyber 肖 end
    if (!request.searchWord) {
      delete request.searchWord;
    }
    if (!request.searchDate) {
      delete request.searchDate;
    }
    return request;
  }

  // 20210118 NEW_DEV-743 cyber 肖 start
  /**
   * dataから検索用のリクエストを作成する
   */
  private loopsiloopCreateRequest() {
    const request = this.tableOptions as SearchRequest;
    request.id = this.loopsiloopInputOptions.id;
    request.type = this.loopsiloopInputOptions.type;
    request.searchWord = this.loopsiloopInputOptions.searchWord;
    request.searchDate = this.loopsiloopInputOptions.searchDate;
    if (!request.searchWord) {
      delete request.searchWord;
    }
    if (!request.searchDate) {
      delete request.searchDate;
    }
    return request;
  }
  // 20210118 NEW_DEV-743 cyber 肖 end
  /**
   * ボタンの見た目を更新する
   */
  private changeButtonParams() {
    // 20210118 NEW_DEV-743 cyber 肖 start
    this.inputOptions.searchWord = null;
    this.inputOptions.searchDate = null;
    // 20210118 NEW_DEV-743 cyber 肖 end
    this.allOutlined = true;
    this.replyOutlined = true;
    this.flgOutlined = true;
    this.allColor = UNSELECTED_COLOR;
    this.replyColor = UNSELECTED_COLOR;
    this.flgColor = UNSELECTED_COLOR;
    switch (this.inputOptions.type) {
      case "all":
        this.allOutlined = false;
        this.allColor = SELECTED_COLOR;
        break;
      case "reply":
        this.replyOutlined = false;
        this.replyColor = SELECTED_COLOR;
        break;
      case "flg":
        this.flgOutlined = false;
        this.flgColor = SELECTED_COLOR;
        break;
    }
  }

  // 20210126 NEW_DEV-743 cyber 肖 start
  async loopsiloop(interval: Number) {
    //NEW_DEV-1484 cyber start
    this.talkFlgArr = [];
    //NEW_DEV-1484 cyber end
    this.timer = setTimeout(() => {
      this.newDataFlg = true;
      this.loopsiloopSearch();
      if(this.newSuccessFlg && !this.isLoading){
        interval = 60000;
        this.loopsiloop(interval);
      }else{
        if(++this.failed < 10){
          interval = interval + 1000;
          this.loopsiloop( interval );
        }
      }
    }, interval);
  }

  clearUnread() {
    this.loopsiloopSearch();
  }
  // 20210126 NEW_DEV-743 cyber 肖 end

  // NEW_DEV-1481 cyber start
  async toPage(page: number) {
    this.tableOptions.page = page;
    await this.search();
    this.footPageFormat();
  }

  async nextPage() {
    if(this.tableOptions.page < this.pageTotal) {
      this.tableOptions.page++;
      await this.search();
      this.footPageFormat();
    }
  }

  async prevPage() {
    if(this.tableOptions.page > 1) {
      this.tableOptions.page--;
      await this.search();
      this.footPageFormat();
    }
  }

  footPageFormat() {
    let startPage = 1;
    let endPage = 10;
    if(this.tableOptions.page < 10) { 
      if(this.pageTotal < 10) {
        endPage = this.pageTotal;
      }
    }else{
      startPage = this.tableOptions.page - 4;
      endPage = this.tableOptions.page + 5;
      if(this.pageTotal < endPage) {
        endPage = this.pageTotal;
      }
    }
    this.pageArr = [];
    for(let i = startPage; i <= endPage; i++){
      this.pageArr.push(i); 
    }
  }

  isFirst() {
    if(this.tableOptions.page == 1) {
      return 'color:black';
    }
  }

  isLast() {
    if(this.tableOptions.page == this.pageTotal || this.pageTotal == 0) {
      return 'color:black';
    }
  }

  isSelf(num: number) {
    if(this.tableOptions.page == num) {
      return 'color:black';
    }
  }

  registUserPage() {
    this.tableOptions.page = 1;
    this.pageTotal = Math.ceil(this.totalCount / this.tableOptions.displayCount);
    this.footPageFormat();
  }
  // NEW_DEV-1481 cyber end
}
