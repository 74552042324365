













import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class UITextField extends Vue {
    @Prop() label?: string;
    @Prop() defaultWord?: string;
    @Prop() textChangeCallback!: (word: string | null) => void;

    callBackData() {
        this.textChangeCallback(this.defaultWord);
    }
}
