import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as TalkFlgAPI from "@/api/talk-flg";
import { CommonResponse, isSuccess } from "@/api/response";
import { UpdateRequest } from "@/api/talk-flg/request";

const MODULE_NAME = "talk-flg/update";

/**
 * トーク情報フラグ更新APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Update extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  @MutationAction
  async update(updateRequest: UpdateRequest) {
    const commonResponse = await TalkFlgAPI.update(updateRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Update);
