







import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class UlContentHeader extends Vue {
  @Prop() headingMain!: string;
  @Prop() headingSub?: string;
}
