import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchResponse, SearchItem } from "@/api/talk-user-list/response";
import { SearchRequest } from "@/api/talk-user-list/request";
import * as TalkUserListAPI from "@/api/talk-user-list";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "talk-user-list/search";
const USER_NAME_MAX = 10;
const CONTENT_MAX = 20;

/**
 * トークリスト一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  get talkId() {
    return this._item.talkId;
  }
  get userId() {
    return this._item.userId;
  }
  get userName() {
    if (this._item.userName.length > USER_NAME_MAX) {
      return this._item.userName.substr(0, USER_NAME_MAX) + "…";
    }
    return this._item.userName;
  }
  get content() {
    // 20210125 NEW_DEV-743 cyber 肖 start
    if(this._item.content) {
      this._item.content = this._item.content.replace(/<br\/>/g, ' ');
      if(this._item.content.length > CONTENT_MAX) {
        return this._item.content.substr(0, CONTENT_MAX) + "…";
      }
    }
    // 20210125 NEW_DEV-743 cyber 肖 end
    return this._item.content;
  }
  get isNotReply() {
    return this._item.replyFlg === 0;
  }
  get isOnFlag() {
    return this._item.flg === 1;
  }
  get unreadCount() {
    return this._item.unreadCount;
  }
  get updateDate() {
    // NEW_DEV-1482 cyber start
    if(!this._item.updateDate) {
      return "";
    }
    // NEW_DEV-1482 cyber end
    const updateDate = new Date(this._item.updateDate);
    if (!updateDate) {
      return "";
    }
    const todayZeroTime = new Date(new Date().setHours(0, 0, 0, 0));
    
    const nowDate = new Date();
    const diffSec = Math.floor(
      (nowDate.getTime() - updateDate.getTime()) / 1000
    );
    const day = 60 * 60 * 24;
    const wDays = day * 7;
    if (updateDate.getTime() > todayZeroTime.getTime()) {
      return `${updateDate.getHours()}:${(
        "00" + updateDate.getMinutes()
      ).substr(-2)}`;
    } else if (diffSec < wDays) {
      return [
        "日曜日",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日"
      ][updateDate.getDay()];
    }
    return `${updateDate.getFullYear()}/${ListItem.formatMonth(
      updateDate.getMonth() + 1
    )}/${ListItem.formatMonth(updateDate.getDate())}`;
  }

  static formatMonth(val: number) {
    return ("00" + val).substr(-2);
  }
}

/**
 * トークリスト取得API（/talk-user-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.items || []).map(
        v => new ListItem(v)
      );
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  // 20210113 NEW_DEV-743 cyber 肖 start
  get getSetting() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.setting || [])
    } else {
      return [];
    }
  }
  // 20210113 NEW_DEV-743 cyber 肖 end

  // NEW_DEV-1481 cyber start
  get getperpageNum() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.perpageNum || 100)
    } else {
      return 100;
    }
  }
  // NEW_DEV-1481 cyber end

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await TalkUserListAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
